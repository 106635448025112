body {
    margin: 0;

    padding: 0;
    /* -moz-font-feature-settings: "ss02";
    -webkit-font-feature-settings: "ss02";
    font-feature-settings: "ss02"; */
    font-family:IRANSansX;
  }
  @font-face {
    font-family: 'IRANSansX';
    src: local('IRANSansX'), url(../../public/fonts/woff/IRANSansX-Regular.woff) format('woff');
  }
  *:focus {
    outline: none;
  }
  .tablecell {
    font-size: 14px !important;
  }
  .Toastify{
    font-size: 16px;
  }